$(function() {
    if ($('#typed').length) {
        var typed = new Typed('#typed', {
            stringsElement: '#typed-strings',
            loop: true,
            typeSpeed: 100,
            backSpeed: 20
        });
    }
});

$('.lead-form').on('submit', function(e) {
    e.preventDefault();

    var form = $(this);
    var isError = false;
    var error = form.find('.error').first();
    var info = form.find('.info').first();

    $.each(form.serializeArray(), function(i, item) {
        if (item.name == 'name' && item.value == '') {
            isError = true;
            error.html('Por favor, rellena un nombre de contacto.');
            error.show();

            return false;
        } else if (item.name == 'email' && item.value == '') {
            isError = true;
            error.html('Por favor, rellena un email para ponernos en contacto contigo.');
            error.show();

            return false;
        } else if (item.name == 'email' && !validateEmail(item.value)) {
            isError = true;
            error.html('Por favor, escribe una dirección de email válida.');
            error.show();

            return false;
        } else if (item.name == 'message' && item.value == '') {
            isError = true;
            error.html('Por favor, escribe un mensaje para que podamos estudiar tu caso.');
            error.show();

            return false;
        }
    });

    if (!isError) {
        error.html('&nbsp;');
        error.hide();
    } else {
        return false;
    }

    $.ajax({
        type: 'post',
        url: path + '/api/leadForm/',
        data: form.serialize(),
        dataType: 'json',
        success: function(result)
        {
            if (result.status == 'ok') {
                form.trigger('reset');
                error.html('&nbsp;');
                error.hide();
                info.html('Enviado.');
                setTimeout(function () {
                    info.html('&nbsp;');
                }, 3000);
            } else if (result.status == 'error') {
                $.each(result.errors, function(i, item) {
                    error.html(item);
                    error.show();

                    return;
                });
                info.html('&nbsp;');
            }
        },
        beforeSend: function()
        {
            info.html('Enviando...');
        },
        error: function()
        {
            error.html('No se ha podido enviar debido a un error en el servicio. Por favor, inténtalo de nuevo.');
            error.show();
            info.html('&nbsp;');
        }
    });
});

function validateEmail(email)
{
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
}

function validatePhone(phone)
{
    var phone = phone.replace(/ |-|\./g, '');
    var regex = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;

    return regex.test(phone);
}